import { useEffect, useState } from 'react';
import { Icon } from '@/components/common';
import {
  notificationsClasses,
  notificationsCloseIconClasses,
  notificationsCloseWrapperClasses,
  notificationsIconWrapperClasses,
} from '@/components/common/Notifications/lib/NotificationClasses';
import { useNotifications } from '@/hooks/useNotifications';
import { renderClasses } from '@/utils';
import { INotifications } from '@/types';
import './Notification.styles.css';

export const Notification = (props: INotifications) => {
  const { dispatchNotificationRemove } = useNotifications();

  const {
    children,
    id,
    variant = 'info',
    template = 'cloud',
    icon = { iconName: 'check-circle', prefix: 'fal' },
    time = 5,
    canClose = time < 0,
    onClose = () => dispatchNotificationRemove(id),
    size = 'md',
    layout,
  } = props;

  const [isHover, setIsHover] = useState(false);
  const [removeTime, setRemoveTime] = useState<number>(() => time);

  useEffect(() => {
    if (isHover || !id) {
      return;
    }

    if (removeTime === 0) {
      return dispatchNotificationRemove(id);
    }

    const waitingTimeForRemoval = setTimeout(() => {
      setRemoveTime((prev) => prev - 1);
    }, 1000);

    return () => {
      clearTimeout(waitingTimeForRemoval);
    };

  }, [removeTime, isHover, id]);

  return (
    <div
      className={notificationsClasses({ template, variant, size, layout })}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className={notificationsIconWrapperClasses({ template, size })}>
        {!!icon && <Icon icon={icon} className='h-full' />}
      </div>
      <div
        className={renderClasses({
          'inline-flex h-full leading-normal mb-0 mt-2': true,
          'flex-1': template !== 'grid',
          'text-xs': size === 'sm',
        })}
      >
        {typeof children === 'string' && (
          <div dangerouslySetInnerHTML={{ __html: children }} />
        )}
        {typeof children !== 'string' && children}
      </div>
      {
        canClose && (
          <div className={notificationsCloseWrapperClasses({ template })}>
            <Icon
              icon={{ iconName: 'close', prefix: 'fal' }}
              className={notificationsCloseIconClasses({ variant })}
              onClick={onClose}
            />
          </div>
        )
      }
    </div>
  );
};
