import { combineReducers } from 'redux';
import { domain as ApprovalModelDomain } from '@/redux/models/ApprovalsModel';
import { domain as FilterModelDomain } from '@/redux/models/FilterModel';
import { FilterSlice } from '@/redux/models/FilterModel/reducer';
import { domain as MainMenuModel } from '@/redux/models/MainMenuModel';
import { domain as MessagesModelDomain } from '@/redux/models/MessagesModel';
import { domain as ReportModelDomain } from '@/redux/models/ReportModel';
import { domain as SettingModelDomain } from '@/redux/models/SettingModel';
import { domain as TaskModelDomain } from '@/redux/models/TaskModel';
import { domain as TimeSheetModelDomain } from '@/redux/models/TimeSheetModel';
import approvalModelReducer from './models/ApprovalsModel/reducer';
import mainMenuModelReducer from './models/MainMenuModel/reducer';
import messagesModelReducer from './models/MessagesModel/reducer';
import reportModelReducer from './models/ReportModel/reducer';
import settingModelReducer from './models/SettingModel/reducer';
import taskModelReducer from './models/TaskModel/reducer';
import timeSheetModelReducer from './models/TimeSheetModel/reducer';

export default function (asyncReducers) {
  const reducers = {
    [FilterModelDomain]: FilterSlice.reducer,
    [ReportModelDomain]: reportModelReducer,
    [TaskModelDomain]: taskModelReducer,
    [SettingModelDomain]: settingModelReducer,
    [MessagesModelDomain]: messagesModelReducer,
    [ApprovalModelDomain]: approvalModelReducer,
    [TimeSheetModelDomain]: timeSheetModelReducer,
    [MainMenuModel]: mainMenuModelReducer.reducer,
    ...asyncReducers,
  };

  return combineReducers(reducers);
}
