
import { GroupSetting, SettingsModelStateFields } from '@/redux/models/SettingModel/types';
import { ApiTokenService } from '@/services/ApiTokenService/ApiTokenService';
import { EntryPermissionChecker } from '@/security/permissions/checkers/EntryPermissionChecker';
import { config } from '@/config';
import StorageFactory from './BrowserStorageService';
import { StorageTypes } from './BrowserStorageService/types';
import ColorService from './ColorService/ColorService';
import DateFormatter from './DateFormatter/DateFormatter';
import DurationFormatter from './DurationFormatter/DurationFormatter';
import GoogleSpreadsheetDataTransformer from './GoogleSpreadsheetExporter/GoogleSpreadsheetDataTransformer';
import GoogleSpreadsheetExporter from './GoogleSpreadsheetExporter/GoogleSpreadsheetExporter';
import NotificationServiceFactory from './NotificationService';
import ReportEntityDataProvider from './ReportEntityDataProvider/ReportEntityDataProvider';
import ClientFactory from './RestApiClientFactory';
import TimeFormatter from './TimeFormatter/TimeFormatter';
import TimeServiceFactory from './TimeServiceFactory';

export const storageServiceFactory = window.getStorageType && new StorageFactory(
  window.getStorageType(),
);

export const storageSupportAngular = new StorageFactory(
  new Promise((resolve) => {
    resolve(StorageTypes.SAFE_LOCAL_STORAGE);
  }),
);
export const restApiClient = ClientFactory(config);
export const timeService = TimeServiceFactory();
export const durationFormatter = new DurationFormatter();
export const timeFormatter = new TimeFormatter();
export const dateFormatter = new DateFormatter();
export const colorService = new ColorService();
export const apiTokenService = new ApiTokenService();

export const reportEntityDataProvider = new ReportEntityDataProvider();
export const googleSpreadsheetDataTransformer = new GoogleSpreadsheetDataTransformer(reportEntityDataProvider);
export const googleSpreadsheetExporter = new GoogleSpreadsheetExporter(
  googleSpreadsheetDataTransformer,
);

// eslint-disable-next-line
// @ts-ignore
export const entryPermissionChecker = new EntryPermissionChecker(config.permissions?.usersPrivilages || []);

// eslint-disable-next-line
// @ts-ignore
timeService.setWeekStart(
  config[SettingsModelStateFields.GROUP_SETTING][GroupSetting.WEEK_START],
);

export const notificationService = NotificationServiceFactory();

export default {
  config,
  restApiClient,
  timeService,
  storageServiceFactory,
  notificationService,
  googleSpreadsheetExporter,
  googleSpreadsheetDataTransformer,
  durationFormatter,
  timeFormatter,
  dateFormatter,
  colorService,
  apiTokenService,
};
