import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ColumnSizingState, SortingState } from '@tanstack/react-table';
import { UsersStatusFilter } from '@/redux/models/UserModel/types';
import { expensesDefaultParams } from '@/reactQuery';
import { ArchivedFilter, DetailedSortColumnTypes } from '@/types';
import {
  FilterModule,
  FilterName,
  FilterPermissions,
  GroupByKeys,
  OrderByValues,
  OrdersName,
  ReportFiltersState,
  TimeframeKeys,
} from './types';

export const initialState: ReportFiltersState = {
  [FilterModule.REPORTS]: {
    [FilterName.TIME_FRAME]: {
      [TimeframeKeys.START_DATE]: new Date(),
      [TimeframeKeys.END_DATE]: new Date(),
    },
    [FilterName.TIME_FRAME_STRING]: 'calendar.today',
    [FilterName.GROUP_BY]: {
      [GroupByKeys.GROUP_1_LEVEL]: '',
    },
    [FilterName.TAGS]: [],
    [FilterName.USERS]: [],
    [FilterName.NOTE]: null,
    [FilterName.TASKS]: [],
    [FilterName.BILLABLE]: null,
    [FilterName.INVOICED]: null,
    [FilterName.ARCHIVED_TASKS]: ArchivedFilter.FILTER_ARCHIVED_NO_FILTERING,
  },
  [FilterModule.ORDERS]: {
    [OrdersName.DETAILED_ORDER]: [{
      id: DetailedSortColumnTypes.DURATION,
      desc: true,
    }],
    [OrdersName.SUMMARY_ORDER]: {
      column: DetailedSortColumnTypes.DURATION,
      direction: OrderByValues.DESC,
    },
  },
  [FilterModule.SIZING]: {
    [FilterName.DETAILED_SIZE]: {
      [DetailedSortColumnTypes.TASK_NAME]: 510,
      [DetailedSortColumnTypes.USER_NAME]: 250,
      [DetailedSortColumnTypes.DURATION]: 170,
      [DetailedSortColumnTypes.NOTE]: 300,
      [DetailedSortColumnTypes.TIMESTAMP]: 200,
      [DetailedSortColumnTypes.BILLABLE]: 150,
    },
  },
  [FilterModule.PERMISSIONS]: {
    [FilterPermissions.CAN_USER_SELECT_PEOPLE]: false,
  },
  [FilterModule.EXPANSES]: {
    [FilterName.EXPANSE_SORT_ORDER]: expensesDefaultParams.sortOrder,
    [FilterName.EXPANSE_SORT_BY]: expensesDefaultParams.sortBy,
    [FilterName.EXPANSE_TASK_PICKER_STATUS]: expensesDefaultParams.taskPickerStatus,
  },
  [FilterModule.USERS]: {
    [FilterName.STATUS_OF_DISPLAYED_USERS]: UsersStatusFilter['USERS_STATUS_FILTER_USERS_ALL'],
  },
};

export const FilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    CHANGE_FILTER_VALUE: (state, action: PayloadAction<{
      module: FilterModule;
      name: FilterName;
      value: any;
    }>) => {
      const { module, name, value } = action.payload;

      state[module][name] = value;
    },
    REMOVE_FILTER_VALUE: (state, action: PayloadAction<{
      module: FilterModule;
      name: FilterName;
    }>) => {
      const { module, name } = action.payload;

      delete state[module][name];
    },
    CHANGE_ORDER_VALUE: (state, action: PayloadAction<{
      module: FilterModule;
      column: DetailedSortColumnTypes;
      direction: OrderByValues;
    }>) => {
      const { module, column, direction } = action.payload;

      state[module][OrdersName.SUMMARY_ORDER] = {
        direction,
        column,
      };
    },
    CHANGE_ORDER_DETAILED: (state, action: PayloadAction<{
      module: FilterModule;
      sorting: SortingState;
    }>) => {
      const { module, sorting } = action.payload;

      state[module][OrdersName.DETAILED_ORDER] = sorting;
    },
    CHANGE_SIZE_COLUMN: (state, action: PayloadAction<{
      module: FilterModule;
      sizing: ColumnSizingState,
    }>) => {
      const { module, sizing } = action.payload;

      state[module][FilterName.DETAILED_SIZE] = sizing;
    },
    SET_CAN_USER_SELECT_PEOPLE: (state, action: PayloadAction<boolean>) => {
      state.permissions[FilterPermissions.CAN_USER_SELECT_PEOPLE] = action['payload'];
    },
    RESET: (state) => {
      state.reports = {
        ...initialState[FilterModule.REPORTS],
        [FilterName.TIME_FRAME]: state.reports[FilterName.TIME_FRAME],
        [FilterName.TIME_FRAME_STRING]: state.reports[FilterName.TIME_FRAME_STRING],
      };
    },
    RESET_EXPENSES: (state) => {
      state.expanses = {
        ...initialState[FilterModule.EXPANSES],
      };
    },
    RESET_WITHOUT_DISABLED: (state) => {
      state.reports = {
        ...initialState[FilterModule.REPORTS],
        [FilterName.TIME_FRAME]: state.reports[FilterName.TIME_FRAME],
        [FilterName.TIME_FRAME_STRING]: state.reports[FilterName.TIME_FRAME_STRING],
        [FilterName.USERS]: state.reports[FilterName.USERS],
      };
    },
    CAN_USER_SELECT_PEOPLE: (state) => state,
  },
});

export default FilterSlice;
