import { DeleteAccountParams, DeleteAccountResponse } from '@/services/RestApiClientFactory';
import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import { Dict } from '@/types';

export class ApiClientUser extends ApiClientBase {
  async updateUserSetting(userId, params) {
    const { data } = await this.fetchData<Dict<string> | null>({
      queryString: `user/${userId}/setting`,
      method: 'PUT',
      body: params,
      apiVersion: 1,
    });

    return data;
  }

  async getUserSetting(userId, settingName) {
    const { data } = await this.fetchData<Dict<string> | null>({
      queryString: `user/${userId}/setting?name=${settingName}`,
      method: 'GET',
      apiVersion: 1,
    });

    return data;
  }

  async deleteAccount({ userId }: DeleteAccountParams) {
    try {
      const { data, status } = await this.fetchData<DeleteAccountResponse>({
        queryString: `user/${userId}`,
        method: 'DELETE',
        apiVersion: 3,
      });

      if (status === 200) {
        return data;
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
}
