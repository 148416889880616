import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE } from '@/types';
import deTranslation from '@/translations/de_DE.json';
import enDubaiTranslation from '@/translations/en_DUBAI.json';
import enTranslation from '@/translations/en_EN.json';
import esTranslation from '@/translations/es_ES.json';
import frTranslation from '@/translations/fr_FR.json';
import plTranslation from '@/translations/pl_PL.json';
import ptTranslation from '@/translations/pt_BR.json';

const DEFAULT_LANGUAGE = LANGUAGE.EN;

const translations = {
  [LANGUAGE.EN]: { translation: enTranslation },
  [LANGUAGE.EN_DUBAI]: { translation: enDubaiTranslation },
  [LANGUAGE.PL]: { translation: plTranslation },
  [LANGUAGE.DE]: { translation: deTranslation },
  [LANGUAGE.PT]: { translation: ptTranslation },
  [LANGUAGE.ES]: { translation: esTranslation },
  [LANGUAGE.FR]: { translation: frTranslation },
};

i18n
  .use(initReactI18next)
  .init({
    resources: translations,
    fallbackLng: DEFAULT_LANGUAGE,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    lng: typeof REACT_CONFIG !== 'undefined' && REACT_CONFIG?.language || DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    returnEmptyString: false,
  });

export { DEFAULT_LANGUAGE };
export default i18n;
