import { fork, put, select, takeLatest } from 'redux-saga/effects';
import FilterSlice from '@/redux/models/FilterModel/reducer';
import { fetchGroupedSummaryReport, onFilterChanged, summaryReportSort } from '@/redux/models/ReportModel/actions';
import { fetchTasks } from '@/redux/models/TaskModel/actions';
import { ArchivedFilter } from '@/redux/models/TaskModel/types';
import { restApiClient } from '@/services';
import { PartialPayloadAction } from '@/types/reduxTypes';
import { config } from '@/config';
import { FilterModule, FilterName, GroupByKeys } from './types';
import { selectAllFilters } from './selectors';

const {
  CHANGE_FILTER_VALUE,
  SET_CAN_USER_SELECT_PEOPLE,
  CAN_USER_SELECT_PEOPLE,
  CHANGE_ORDER_VALUE,
} = FilterSlice.actions;

export const FilterModelSagas = [
  function* () {
    yield fork(function* () {
      yield takeLatest(CHANGE_FILTER_VALUE.type, onChangeFilterValue);
    });
    yield fork(function* () {
      yield takeLatest(CHANGE_ORDER_VALUE.type, onChangeOrderValue);
    });
    yield fork(function* () {
      yield takeLatest(CAN_USER_SELECT_PEOPLE.type, canUserSelectPeople);
    });
  },
];

export function* onChangeFilterValue({
  payload,
}: PartialPayloadAction<{
  module: string;
  name: string;
  value: any;
}>) {
  const { name } = payload;
  const reportFilters = yield select(selectAllFilters, {
    module: FilterModule.REPORTS,
  });
  switch (name) {
    case FilterName.GROUP_BY: {
      const firstGroup =
        reportFilters[FilterName.GROUP_BY][GroupByKeys.GROUP_1_LEVEL];
      const secondGroup =
        reportFilters[FilterName.GROUP_BY][GroupByKeys.GROUP_2_LEVEL];

      yield put(fetchGroupedSummaryReport({ firstGroup, secondGroup }));
      return;
    }

    case FilterName.ARCHIVED_TASKS: {
      const archivedFilter: ArchivedFilter =
        reportFilters[FilterName.ARCHIVED_TASKS];
      yield put(fetchTasks({ archivedFilter }));
      return;
    }

    default: {
      yield put(onFilterChanged(reportFilters));
    }
  }
}

export function* onChangeOrderValue() {
  yield put(summaryReportSort());
}

export function* canUserSelectPeople() {
  const canSelect = yield restApiClient.timeTracking.fetchCanUserSelect();

  if (!canSelect && config.userId) {
    yield put(SET_CAN_USER_SELECT_PEOPLE(false));
    yield put(CHANGE_FILTER_VALUE({
      module: FilterModule.REPORTS,
      name: FilterName.USERS,
      value: [config.userId],
    }));
  } else {
    yield put(SET_CAN_USER_SELECT_PEOPLE(true));
  }
}
