import { format } from 'date-fns';
import { Column } from '@/redux/models/ReportModel/types';
import { ArchivedFilter } from '@/redux/models/TaskModel/types';
import { UsersStatusFilter } from '@/redux/models/UserModel/types';
import { expensesDefaultParams } from '@/reactQuery';
import {
  FilterName,
  GroupByFilter,
  GroupByFilterDictionary,
  GroupByKeys,
  OrderByValues,
  OrdersName,
  TimeframeKeys,
} from './types';

const currentDate = format(new Date(), 'yyyy-MM-dd');

export const getDefaultFilterValue = (name: FilterName | OrdersName | ''): unknown => {
  switch (name) {
    case FilterName.GROUP_BY:
      return {
        [GroupByKeys.GROUP_1_LEVEL]:
          GroupByFilterDictionary[GroupByFilter.NONE],
        [GroupByKeys.GROUP_2_LEVEL]:
          GroupByFilterDictionary[GroupByFilter.NONE],
      };

    case FilterName.TIME_FRAME:
      return {
        [TimeframeKeys.START_DATE]: currentDate,
        [TimeframeKeys.END_DATE]: currentDate,
      };

    case FilterName.ARCHIVED_TASKS:
      return ArchivedFilter.FILTER_ARCHIVED_NO_FILTERING;

    case FilterName.STATUS_OF_DISPLAYED_USERS:
      return UsersStatusFilter.USERS_STATUS_FILTER_USERS_ALL;

    case FilterName.TIME_FRAME_STRING:
    case FilterName.NOTE:
      return null;

    case FilterName.TAGS:
    case FilterName.USERS:
    case FilterName.TASKS:
      return [];

    case FilterName.BILLABLE:
    case FilterName.INVOICED:
    case FilterName.APPROVAL_STATUS:
      return null;

    case OrdersName.SUMMARY_ORDER:
      return {
        direction: OrderByValues.ASC,
        column: Column.TRACKED_TIME,
      };

    case FilterName.EXPANSE_TASK_PICKER_STATUS:
      return expensesDefaultParams.taskPickerStatus;

    default:
      return null;
  }
};
