import * as DateFns from 'date-fns';
import isNumber from 'lodash/isNumber';
import { convertEnumValuesToArray } from '@/utils';
import { DurationFormat, Maybe, PredefinedDateRange, TimeUnits } from '@/types';
import { Duration, IWeekStart } from './types';
import * as constants from './constants';
import { parseDurationToFormat } from './helpers';
import TimeServiceInterface from './interface';

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60;

export default class TimeService implements TimeServiceInterface {
  private weekStart: IWeekStart = 1;

  getWeekStart() {
    return this.weekStart;
  }

  setWeekStart(weekStart: IWeekStart) {
    this.weekStart = weekStart;
  }

  timestampToFormat(timestamp: number, format: string): string {
    return DateFns.format(new Date(timestamp), format);
  }

  dateToTimestamp(date: Date) {
    return date.getTime();
  }

  dateToTimestampInSeconds(date: Date) {
    const timestamp = date.getTime();
    return this.parseMillisecondsToSecond(timestamp);
  }

  dateToFormat(date: Date, format: string): string {
    return DateFns.format(date, format);
  }

  dateRangeToFormats(
    startDate: Date,
    endDate: Date,
    startFormat = 'MMM dd',
    endFormat = 'MMM dd, yyyy',
    duplicateSameValues = false,
  ): string {
    let result = '';

    if (DateFns.isSameDay(startDate, endDate) && !duplicateSameValues) {
      result = `${this.dateToFormat(endDate, endFormat)}`;
    } else {
      result = `${this.dateToFormat(startDate, startFormat)} - ${this.dateToFormat(endDate, endFormat)}`;
    }

    return result;
  }

  parseMillisecondsToDays(milliseconds: number, floatOutput = false): number {
    const value = milliseconds / constants.ONE_DAY_IN_MILLISECONDS;
    return floatOutput
      ? value
      : Math.floor(value);
  }

  parseMillisecondsToHours(milliseconds: number, floatOutput = false): number {
    const value = milliseconds / constants.ONE_HOUR_IN_MILLISECONDS;
    return floatOutput
      ? value
      : Math.floor(value);
  }

  parseMillisecondsToMinutes(milliseconds: number, floatOutput = false): number {
    const value = milliseconds / constants.ONE_MINUTE_IN_MILLISECONDS;
    return floatOutput
      ? value
      : Math.floor(value);
  }

  parseMillisecondsToSeconds(milliseconds: number, floatOutput = false): number {
    const value = milliseconds / constants.ONE_SECOND_IN_MILLISECONDS;
    return floatOutput
      ? value
      : Math.floor(value);
  }

  calculateDuration = (
    milliseconds: number,
    firstTimeUnit: TimeUnits,
    lastTimeUnit: TimeUnits,
  ): Duration => {
    const units = convertEnumValuesToArray(TimeUnits);
    const firstTimeUnitIndex = units.indexOf(firstTimeUnit);
    const lastTimeUnitIndex = units.indexOf(lastTimeUnit);
    let value = milliseconds;
    let days: number | null = null;
    let hours: number | null = null;
    let minutes: number | null = null;

    if (firstTimeUnitIndex <= units.indexOf(TimeUnits.DAYS)
      && lastTimeUnitIndex >= units.indexOf(TimeUnits.DAYS)
    ) {
      days = this.parseMillisecondsToDays(value);
      value -= (days * constants.ONE_DAY_IN_MILLISECONDS);
    }

    if (firstTimeUnitIndex <= units.indexOf(TimeUnits.HOURS)
      && lastTimeUnitIndex >= units.indexOf(TimeUnits.HOURS)
    ) {
      hours = this.parseMillisecondsToHours(value);
      value -= (hours * constants.ONE_HOUR_IN_MILLISECONDS);
    }

    if (firstTimeUnitIndex <= units.indexOf(TimeUnits.MINUTES)
      && lastTimeUnitIndex >= units.indexOf(TimeUnits.MINUTES)
    ) {
      minutes = this.parseMillisecondsToMinutes(value);
      value -= (minutes * constants.ONE_MINUTE_IN_MILLISECONDS);
    }

    const seconds = this.parseMillisecondsToSeconds(value);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  calculateDurationInDecimal(
    milliseconds: number,
    timeUnit: TimeUnits,
  ): string {
    const units = convertEnumValuesToArray(TimeUnits);
    const index = units.indexOf(timeUnit);
    let result = 0;

    if (index >= 0) {
      switch (timeUnit) {
        case TimeUnits.DAYS: {
          result = this.parseMillisecondsToDays(milliseconds, true);
          break;
        }
        case TimeUnits.HOURS: {
          result = this.parseMillisecondsToHours(milliseconds, true);
          break;
        }
        case TimeUnits.MINUTES: {
          result = this.parseMillisecondsToMinutes(milliseconds, true);
          break;
        }
        case TimeUnits.SECONDS: {
          result = this.parseMillisecondsToSeconds(milliseconds, true);
          break;
        }
      }
    }

    return `${result.toFixed(2)} ${timeUnit.charAt(0)}`;
  }

  convertTimestampToHumanDuration = (
    timestamp: number,
    firstTimeUnit: TimeUnits,
    lastTimeUnit: TimeUnits,
    format: DurationFormat = DurationFormat.FORMAT_CLASSIC,
  ): string => {
    let result = '';

    switch (format) {
      case DurationFormat.FORMAT_CLASSIC: {
        const duration = this.calculateDuration(timestamp, firstTimeUnit, lastTimeUnit);
        result = parseDurationToFormat(duration, format, firstTimeUnit, lastTimeUnit);
        break;
      }
      case DurationFormat.FORMAT_HH_MM: {
        const duration = this.calculateDuration(timestamp, TimeUnits.HOURS, TimeUnits.MINUTES);
        result = parseDurationToFormat(duration, format, TimeUnits.HOURS, TimeUnits.MINUTES);
        break;
      }
      case DurationFormat.FORMAT_DECIMAL_WITH_COMMA: {
        const duration = this.calculateDurationInDecimal(timestamp, firstTimeUnit);
        result = duration.replace('.', ',');
        break;
      }
      case DurationFormat.FORMAT_DECIMAL_WITH_DOT: {
        result = this.calculateDurationInDecimal(timestamp, firstTimeUnit);
        break;
      }
      case DurationFormat.FORMAT_CLASSIC_WITH_SECONDS: {
        const duration = this.calculateDuration(timestamp, firstTimeUnit, lastTimeUnit);
        result = parseDurationToFormat(duration, format, firstTimeUnit, TimeUnits.SECONDS);
        break;
      }
      case DurationFormat.FORMAT_HH_MM_SS: {
        const duration = this.calculateDuration(timestamp, TimeUnits.HOURS, TimeUnits.SECONDS);
        result = parseDurationToFormat(duration, format, TimeUnits.HOURS, TimeUnits.SECONDS);
        break;
      }
    }

    return result;
  };

  parseHumanDurationToTimestamp = (humanDuration: string): number => {
    let timestamp = 0;
    const humanDurationArray = humanDuration.split(' ');

    const getUnitValue = (segment) => {
      const value = segment.slice(0, segment.length - 1);
      const unit = segment.slice(segment.length - 1, segment.length);

      switch (unit) {
        case TimeUnits.DAYS.charAt(0): {
          return timestamp += value * constants.ONE_DAY_IN_MILLISECONDS;
        }
        case TimeUnits.HOURS.charAt(0): {
          return timestamp += value * constants.ONE_HOUR_IN_MILLISECONDS;
        }
        case TimeUnits.MINUTES.charAt(0): {
          return timestamp += value * constants.ONE_MINUTE_IN_MILLISECONDS;
        }
        case TimeUnits.SECONDS.charAt(0): {
          return timestamp += value * constants.ONE_SECOND_IN_MILLISECONDS;
        }
      }
    };

    humanDurationArray.map(getUnitValue);
    return timestamp;
  };

  validateHumanDuration = (humanDuration: string): boolean => {
    // check if there are chars different than dhms, digits, and spaces
    const charsReg = /[^dhms\d ]/;
    if (charsReg.test(humanDuration)) {
      return false;
    }

    // check if there is syntax valid
    const numberOfSegments = ((humanDuration || '').match(/[dhms]/g) || []).length;
    let regString = '^';
    if (numberOfSegments > 1) {
      regString += '(\\d+)([dhms]{1}) '.repeat(numberOfSegments - 1);
    }
    regString += '(\\d+)([dhms]{1})';

    const syntaxReg = new RegExp(regString);

    return syntaxReg.test(humanDuration);
  };

  getCurrentTimestamp = (timeUnit: TimeUnits = TimeUnits.SECONDS): number => {
    const getUnitValue = (timeUnit: TimeUnits) => {
      switch (timeUnit) {
        case TimeUnits.DAYS: {
          return constants.ONE_DAY_IN_MILLISECONDS;
        }
        case TimeUnits.HOURS: {
          return constants.ONE_HOUR_IN_MILLISECONDS;
        }
        case TimeUnits.MINUTES: {
          return constants.ONE_MINUTE_IN_MILLISECONDS;
        }
        case TimeUnits.SECONDS: {
          return constants.ONE_SECOND_IN_MILLISECONDS;
        }
        case TimeUnits.MILLISECONDS: {
          return 1;
        }
      }
    };

    return Math.floor(Date.now() / getUnitValue(timeUnit));
  };

  getTimerTimeUnits = (
    durationInSeconds: number,
  ): { first: TimeUnits; last: TimeUnits } => {
    // default: show only seconds
    const result = {
      first: TimeUnits.SECONDS,
      last: TimeUnits.SECONDS,
    };

    if (MINUTE_IN_SECONDS <= durationInSeconds && durationInSeconds < HOUR_IN_SECONDS) {
      // show minutes and seconds
      result.first = TimeUnits.MINUTES;
    } else if (HOUR_IN_SECONDS <= durationInSeconds) {
      // show hours and minutes
      result.first = TimeUnits.HOURS;
      result.last = TimeUnits.MINUTES;
    }

    return result;
  };

  isSameDay = (
    firstDate: Date,
    secondDate: Date,
  ): boolean => DateFns.isSameDay(firstDate, secondDate);

  compareDates = (
    firstDate: Date | number,
    secondDate: Date | number,
    unit: TimeUnits = TimeUnits.DAYS,
  ): number => {
    switch (unit) {
      case TimeUnits.DAYS:
      default: {
        return DateFns.differenceInDays(firstDate, secondDate);
      }
    }
  };

  isDateBetween = (
    date: Date,
    startDate: Date,
    endDate: Date,
  ): boolean => this.compareDates(startDate, date) < 0 && 0 < this.compareDates(endDate, date);

  addSeconds = (
    date: Date,
    value = 1,
  ): Date => DateFns.addDays(date, value);

  addDays = (
    date: Date,
    value = 1,
  ): Date => DateFns.addDays(date, value);

  addMonths = (
    date: Date,
    value = 1,
  ): Date => DateFns.addMonths(date, value);

  addYears = (
    date: Date,
    value = 1,
  ): Date => DateFns.addYears(date, value);

  subtractDays = (
    date: Date,
    value = 1,
  ): Date => DateFns.subDays(date, value);

  subtractMonths = (
    date: Date,
    value = 1,
  ): Date => DateFns.subMonths(date, value);

  subtractYears = (
    date: Date,
    value = 1,
  ): Date => DateFns.subYears(date, value);

  setHours = (
    date: Date,
    value: number,
  ): Date => DateFns.setHours(date, value);

  setDay = (
    date: Date,
    value: number,
  ): Date => DateFns.setDate(date, value);

  setMonth = (
    date: Date,
    value: number,
  ): Date => DateFns.setMonth(date, value);

  setYear = (
    date: Date,
    value: number,
  ): Date => DateFns.setYear(date, value);

  getYear = (
    date: Date,
  ): number => DateFns.getYear(date);

  getWeekRange = (
    date: Date,
  ): { startDate: Date; endDate: Date } => {
    const weekStart = this.getWeekStart();
    return {
      startDate: DateFns.startOfWeek(date, { weekStartsOn: weekStart }),
      endDate: DateFns.endOfWeek(date, { weekStartsOn: weekStart }),
    };
  };

  getPredefinedDateRange = (
    dateRange: PredefinedDateRange,
  ): {
    startDate: Maybe<Date>;
    endDate: Maybe<Date>;
  } => {
    let startDate: Maybe<Date> = null;
    let endDate: Maybe<Date> = null;
    const weekStart = this.getWeekStart();
    const today = new Date();

    switch (dateRange) {
      case PredefinedDateRange.TODAY: {
        startDate = today;
        endDate = today;
        break;
      }
      case PredefinedDateRange.YESTERDAY: {
        const yesterday = DateFns.startOfYesterday();
        startDate = yesterday;
        endDate = yesterday;
        break;
      }
      case PredefinedDateRange.THIS_WEEK: {
        startDate = DateFns.startOfWeek(today, { weekStartsOn: weekStart });
        endDate = DateFns.endOfWeek(today, { weekStartsOn: weekStart });
        break;
      }
      case PredefinedDateRange.LAST_WEEK: {
        const previousWeek = DateFns.subWeeks(today, 1);
        startDate = DateFns.startOfWeek(previousWeek, { weekStartsOn: weekStart });
        endDate = DateFns.endOfWeek(previousWeek, { weekStartsOn: weekStart });
        break;
      }
      case PredefinedDateRange.THIS_MONTH: {
        startDate = DateFns.startOfMonth(today);
        endDate = DateFns.endOfMonth(today);
        break;
      }
      case PredefinedDateRange.LAST_MONTH: {
        const previousMonth = DateFns.subMonths(today, 1);
        startDate = DateFns.startOfMonth(previousMonth);
        endDate = DateFns.endOfMonth(previousMonth);
        break;
      }
      case PredefinedDateRange.THIS_YEAR: {
        startDate = DateFns.startOfYear(today);
        endDate = DateFns.endOfYear(today);
        break;
      }
      case PredefinedDateRange.LAST_YEAR: {
        const previousYear = DateFns.subYears(today, 1);
        startDate = DateFns.startOfYear(previousYear);
        endDate = DateFns.endOfYear(previousYear);
        break;
      }
    }

    return {
      startDate,
      endDate,
    };
  };

  calculatePredefinedDateRange = (
    startDate: Date | null | undefined,
    endDate: Date | null | undefined,
  ): PredefinedDateRange => {

    if (startDate && endDate && this.isAllTimeDateRange(startDate, endDate)) {
      return PredefinedDateRange.ALL_TIME;
    }

    if (!startDate || !endDate) {
      return PredefinedDateRange.CUSTOM;
    }

    // TODAY
    if (DateFns.isToday(startDate) && DateFns.isToday(endDate)) {
      return PredefinedDateRange.TODAY;
    }

    // YESTERDAY
    if (DateFns.isYesterday(startDate) && DateFns.isYesterday(endDate)) {
      return PredefinedDateRange.YESTERDAY;
    }

    // THIS WEEK
    const now = new Date();
    const weekStart = this.getWeekStart();
    const startOfCurrentWeek = DateFns.startOfWeek(now, { weekStartsOn: weekStart });
    const endOfCurrentWeek = DateFns.endOfWeek(now, { weekStartsOn: weekStart });

    if (DateFns.isSameDay(startDate, startOfCurrentWeek) && DateFns.isSameDay(endDate, endOfCurrentWeek)) {
      return PredefinedDateRange.THIS_WEEK;
    }

    // LAST WEEK
    const previousWeek = DateFns.subWeeks(now, 1);
    const startOfPreviousWeek = DateFns.startOfWeek(previousWeek, { weekStartsOn: weekStart });
    const endOfPreviousWeek = DateFns.endOfWeek(previousWeek, { weekStartsOn: weekStart });

    if (DateFns.isSameDay(startDate, startOfPreviousWeek) && DateFns.isSameDay(endDate, endOfPreviousWeek)) {
      return PredefinedDateRange.LAST_WEEK;
    }

    // THIS MONTH
    const startOfCurrentMonth = DateFns.startOfMonth(now);
    const endOfCurrentMonth = DateFns.endOfMonth(now);

    if (DateFns.isSameDay(startDate, startOfCurrentMonth) && DateFns.isSameDay(endDate, endOfCurrentMonth)) {
      return PredefinedDateRange.THIS_MONTH;
    }

    // LAST MONTH
    const previousMonth = DateFns.subMonths(now, 1);
    const startOfPreviousMonth = DateFns.startOfMonth(previousMonth);
    const endOfPreviousMonth = DateFns.endOfMonth(previousMonth);

    if (DateFns.isSameDay(startDate, startOfPreviousMonth) && DateFns.isSameDay(endDate, endOfPreviousMonth)) {
      return PredefinedDateRange.LAST_MONTH;
    }

    // THIS YEAR
    const startOfThisYear = DateFns.startOfYear(now);
    const endOfThisYear = DateFns.endOfYear(startOfThisYear);

    if (DateFns.isSameDay(startDate, startOfThisYear) && DateFns.isSameDay(endDate, endOfThisYear)) {
      return PredefinedDateRange.THIS_YEAR;
    }

    // LAST YEAR
    const previousYear = DateFns.subYears(now, 1);
    const startOfPreviousYear = DateFns.startOfYear(previousYear);
    const endOfPreviousYear = DateFns.endOfYear(previousYear);

    if (DateFns.isSameDay(startDate, startOfPreviousYear) && DateFns.isSameDay(endDate, endOfPreviousYear)) {
      return PredefinedDateRange.LAST_YEAR;
    }

    return PredefinedDateRange.CUSTOM;
  };

  getNextPeriod = (
    startDate: Date,
    endDate: Date,
  ): {
    startDate: Date,
    endDate: Date
  } => {
    if (DateFns.isFirstDayOfMonth(startDate) && DateFns.isLastDayOfMonth(endDate)) {
      const diffInMonths = Math.abs(DateFns.differenceInCalendarMonths(startDate, endDate)) + 1;

      return {
        startDate: DateFns.addMonths(startDate, diffInMonths),
        endDate: DateFns.lastDayOfMonth(DateFns.addMonths(endDate, diffInMonths)),
      };
    }
    const diffInDays = Math.abs(DateFns.differenceInCalendarDays(startDate, endDate)) + 1;

    return {
      startDate: DateFns.addDays(startDate, diffInDays),
      endDate: DateFns.addDays(endDate, diffInDays),
    };
  };

  getPreviousPeriod = (
    startDate: Date,
    endDate: Date,
  ): {
    startDate: Date,
    endDate: Date
  } => {
    if (DateFns.isFirstDayOfMonth(startDate) && DateFns.isLastDayOfMonth(endDate)) {
      const diffInMonths = Math.abs(DateFns.differenceInCalendarMonths(startDate, endDate)) + 1;

      return {
        startDate: DateFns.subMonths(startDate, diffInMonths),
        endDate: DateFns.lastDayOfMonth(DateFns.subMonths(endDate, diffInMonths)),
      };
    }

    const diffInDays = Math.abs(DateFns.differenceInCalendarDays(startDate, endDate)) + 1;

    return {
      startDate: DateFns.subDays(startDate, diffInDays),
      endDate: DateFns.subDays(endDate, diffInDays),
    };
  };

  getAllTimeDateRanges = (): { from: Date, to: Date } =>
    // "All time" ranges from 1970 till now
    ({ from: new Date(1), to: DateFns.endOfToday() });
  isAllTimeDateRange = (startDate: Date, endDate: Date): boolean => {
    const allTimeRange = this.getAllTimeDateRanges();

    return DateFns.isEqual(startDate, allTimeRange.from)
      && DateFns.isEqual(endDate, allTimeRange.to);
  };

  startOfWeek = (
    date: Date,
  ): Date => {
    const weekStart = this.getWeekStart();
    return DateFns.startOfWeek(date, { weekStartsOn: weekStart });
  };

  endOfWeek = (
    date: Date,
  ): Date => {
    const weekStart = this.getWeekStart();
    return DateFns.endOfWeek(date, { weekStartsOn: weekStart });
  };

  startOfCurrentDay = (): Date => {
    const today = new Date();
    return DateFns.startOfDay(today);
  };

  endOfCurrentDay = (): Date => {
    const today = new Date();
    return DateFns.endOfDay(today);
  };

  startOfCurrentWeek = () => {
    const today = new Date();
    const weekStart = this.getWeekStart();
    return DateFns.startOfWeek(today, { weekStartsOn: weekStart });
  };

  endOfCurrentWeek = () => {
    const today = new Date();
    return this.endOfWeek(today);
  };

  parseMillisecondsToSecond = (
    timestamp: number | null | undefined,
  ) => {
    if (isNumber(timestamp)) {
      return timestamp ? timestamp / 1000 : timestamp;
    }

    return 0;
  };

  parseSecondsToMilliseconds = (
    timestamp: number | null | undefined,
  ): number => timestamp ? timestamp * 1000 : 0;

  getDates = (startDate: Date, stopDate: Date): Date[] => {
    const dateArray: Date[] = [];
    let currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = DateFns.addDays(currentDate, 1);
    }
    return dateArray;
  };

  createDateFromTimestamp = (timestamp: number): Date => new Date(this.convertUTCTimestampToLocal(timestamp));

  convertUTCTimestampToLocal = (timestamp: number): number => {
    const dt = new Date(timestamp);
    return dt.getTime() + (dt.getTimezoneOffset() * constants.ONE_MINUTE_IN_SECONDS * constants.ONE_SECOND_IN_MILLISECONDS);
  };

  convertLocalTimestampToUTCDate = (timestamp: number): number => {
    const offset = new Date(timestamp).getTimezoneOffset(); // offset is returned in minutes either positive or negative

    return this.calculateTimestampWithoutOffset(timestamp, offset);
  };

  convertLocalTimeToUTC = (timestamp: Date): number => {
    const offset = timestamp.getTimezoneOffset(); // offset is returned in minutes either positive or negative
    const timestampUTC = timestamp.getTime();

    return this.calculateTimestampWithoutOffset(timestampUTC, offset);
  };

  calculateTimestampWithoutOffset = (timestamp: number, offset: number) => {
    const diff = this.parseSecondsToMilliseconds(Math.abs(offset) * constants.ONE_MINUTE_IN_SECONDS);
    let result = timestamp;

    if (offset < 0) {
      result += diff;
    } else {
      result -= diff;
    }

    return result;
  };

  getTimezoneOffset = (date?: Date): number => date ? date.getTimezoneOffset() : new Date().getTimezoneOffset();

  getLastDay = (date: Date): number => DateFns.lastDayOfMonth(date).getDate();

  getDay = (date: Date): number => DateFns.getDate(date);

  getMonth = (date: Date): number => DateFns.getMonth(date);

  isDateBefore = (date: Date, target: Date): boolean => DateFns.isBefore(date, target);

  getCurrentDate = (): Date => new Date();

  max = (dates: Date[]): Date => {
    const validDates = dates.filter(date => !!date);

    return DateFns.max(validDates);
  };

  min = (dates: Date[]): Date => {
    const validDates = dates.filter(date => !!date);

    return DateFns.min(validDates);
  };

  convertMsToTimeUnits = (milliseconds: number) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

    return {
      hours,
      minutes,
      seconds,
    };
  };
}
