import { IFetchTasksParams } from '@/reactQuery';
import { ThemeContextInterface } from '@/contexts/interfaces';

export enum Theme {
  MODERN = 'modern',
  DARK = 'dark',
}

export const defaultThemeContext: ThemeContextInterface = {
  theme: Theme.MODERN,
};

export const TASK_PICKER_SYNC_TOKEN_KEY = (data: Omit<IFetchTasksParams, 'selectFilter'>) => `cache.syncToken.tasks.taskPicker.${Object.values(data).join('.')}`;
export const TASK_PICKER_TASKS_KEY = (data: Omit<IFetchTasksParams, 'selectFilter'>) => `cache.tasks.taskPicker.${Object.values(data).join('.')}`;
