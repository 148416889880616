import { ITextApiErrorHandlerData } from './ApiErrorTextHandler.type';

export class ApiErrorTextHandler extends Error {
  data: ITextApiErrorHandlerData;

  constructor(message: string, data: ITextApiErrorHandlerData) {
    super(message);
    this.message = message;
    this.data = data;
  }
}
