import { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Notification } from '@/components/common';
import { useNotifications } from '@/hooks/useNotifications';

export const NotificationsWrapper = () => {
  const { alerts } = useNotifications();

  const areAlertsToShow = useMemo(() => Array.isArray(alerts) && alerts.length, [alerts]);

  if (!areAlertsToShow) {
    return null;
  }

  return (
    createPortal(
      <div className='fixed flex flex-col top-78 left-1/2 z-[999999] -translate-x-1/2'>
        {
          alerts.map((alert, index) => (
            <Notification key={index} {...alert} />
          ))
        }
      </div>,
      document.body as HTMLElement,
    )
  );
};
