
import { Maybe } from '@/types';
import { StorageTypes } from './types';
import IndexedDBService from './IndexedDBService';
import SafeLocalStorageService from './SafeLocalStorageService';
import SafeSessionStorageService from './SafeSessionStorageService';
import BrowserStorageServiceInterface from './interface';

export default class StorageFactory {
  private storage: BrowserStorageServiceInterface | null = null;
  private typePromise: Promise<Maybe<StorageTypes>>;

  constructor(typePromise: Promise<Maybe<StorageTypes>>) {
    this.typePromise = typePromise;
  }

  public async getStorage() {
    if (this.storage === null) {
      const storageType = await this.typePromise;
      switch (storageType) {
        case StorageTypes.INDEXED_DB:
          this.storage = new IndexedDBService();
          break;
        case StorageTypes.SAFE_LOCAL_STORAGE:
          this.storage = new SafeLocalStorageService();
          break;
        case StorageTypes.SAFE_SESSION_STORAGE:
        default:
          this.storage = new SafeSessionStorageService();
      }
    }

    return this.storage;
  }
}
