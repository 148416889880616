import { ReportType } from '@/redux/models/ReportModel/types';
import { GoogleSpreadsheetExporterEvents } from '@/services/GoogleSpreadsheetExporter/GoogleSpreadsheetExporterEvents';
import { translate } from '@/utils/translate';
import { DurationFormat } from '@/types';
import { GoogleSpreadsheetDataTransformerInterface } from './GoogleSpreadsheetDataTransformerInterface';
import { GoogleSpreadsheetExporterInterface } from './GoogleSpreadsheetExporterInterface';

const VALUE_INPUT_OPTION = 'RAW';

/**
 * @docs https://developers.google.com/sheets/api/quickstart/js
 */
export default class GoogleSpreadsheetExporter implements GoogleSpreadsheetExporterInterface {
  private data;
  private googleSpreadsheetDataTransformer: GoogleSpreadsheetDataTransformerInterface;

  constructor(
    googleSpreadsheetDataTransformer: GoogleSpreadsheetDataTransformerInterface,
  ) {
    this.googleSpreadsheetDataTransformer = googleSpreadsheetDataTransformer;
  }

  async export(data, durationFormat: DurationFormat, type?: ReportType) {
    this.data = this.googleSpreadsheetDataTransformer.transform(
      data,
      durationFormat,
      type,
    );

    window.tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        throw (resp);
      }

      GoogleSpreadsheetExporterEvents.dispatchEvent('google-spreadsheet-exporting:initiated');

      this.#send()
        .then(() => {
          GoogleSpreadsheetExporterEvents.dispatchEvent('google-spreadsheet-exporting:success');
        })
        .catch((error) => {
          GoogleSpreadsheetExporterEvents.dispatchEvent('google-spreadsheet-exporting:error', { error });
        })
        .finally(() => {
          GoogleSpreadsheetExporterEvents.dispatchEvent('google-spreadsheet-exporting:finished');
        });
    };

    if (window.gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      window.tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      window.tokenClient.requestAccessToken({ prompt: '' });
    }
  }

  async #send() {
    return new Promise((resolve, reject) => {
      const START_RANGE = 'A1';
      const sheetsData = [];
      const sheets = [];
      for (const sheetName in this.data) {
        sheets.push({
          properties: {
            title: sheetName,
          },
        });

        const range = `${START_RANGE}:${this.data[sheetName].length}`;
        sheetsData.push({
          range: `${sheetName}!${range}`,
          values: this.data[sheetName],
        });
      }

      const spreadsheetBody = {
        properties: {
          title: translate('GoogleSpreadsheetExporter.timecamp_report'),
        },
        sheets,
      };
      const { spreadsheets } = window.gapi.client.sheets;
      const request = spreadsheets.create({}, spreadsheetBody);
      request
        .then((response) => {
          spreadsheets.values
            .batchUpdate({
              spreadsheetId: response.result.spreadsheetId,
              valueInputOption: VALUE_INPUT_OPTION,
              data: sheetsData,
            })
            .then(() => {
              resolve({
                status: 'Exporting success',
              });
              window.open(response.result.spreadsheetUrl);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
