import { TimeFormatDict } from '@/redux/models/SettingModel/types';
import { MeridianValue } from '@/types';
import { DurationFormatterInterface } from './TimeFormatterInterface';

export default class TimeFormatter implements DurationFormatterInterface {
  formatTime(time: Date, format: TimeFormatDict): string {
    switch (format) {
      case TimeFormatDict.FORMAT_12H: {
        let hours = time.getHours();
        if (hours > 12) {
          hours -= 12;
        }
        if (hours === 0) {
          hours = 12;
        }
        return `${hours}:${this.#padLeft(time.getMinutes())} ${this.#getMeridianValue(time)}`;
      }

      case TimeFormatDict.FORMAT_24H:
        return `${time.getHours()}:${this.#padLeft(time.getMinutes())}`;

      default:
        console.warn(`TimeFormatter: Unknown format: ${format}`);
        return '';
    }
  }

  #padLeft = (data) => data.toString().padStart(2, '0');

  #getMeridianValue = (date) => {
    const hours = date.getHours();
    if (hours >= 12) {
      return MeridianValue.PM;
    }

    return MeridianValue.AM;
  };
}
