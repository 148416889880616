import {
  ApiClientAnalytics,
  ApiClientApprovals,
  ApiClientAssistant,
  ApiClientAuth,
  ApiClientCancelData,
  ApiClientCustomReport,
  ApiClientEntries,
  ApiClientExpense,
  ApiClientFavorites,
  ApiClientFrontendConfig,
  ApiClientGroup,
  ApiClientHelpdesk,
  ApiClientIntegrations,
  ApiClientKiosk,
  ApiClientMainMenu,
  ApiClientModules,
  ApiClientRateType,
  ApiClientReport,
  ApiClientSession,
  ApiClientSurvey,
  ApiClientTagList,
  ApiClientTasks,
  ApiClientTimeEntries,
  ApiClientTimer,
  ApiClientTimeTracking,
  ApiClientUser,
  ApiClientUsers,
} from '@/services/RestApiClientFactory/modules';
import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import { ApiClientCurrency } from '@/services/RestApiClientFactory/modules/ApiClientCurrency/ApiClientCurrency';
import { ApiClientExporter } from '@/services/RestApiClientFactory/modules/ApiClientExporter';
import { ApiClientStorage } from '@/services/RestApiClientFactory/modules/ApiClientStorage';
import {
  ApiClientTrelloPowerUp,
} from '@/services/RestApiClientFactory/modules/ApiClientTrelloPowerUp/ApiClientTrelloPowerUp';
import { ConfigInterface } from '@/services/interfaces';
import { ApiClientGuard } from './modules/ApiClientGuard';

export default class RestApiClient extends ApiClientBase {
  reports: ApiClientReport;
  helpdesk: ApiClientHelpdesk;
  entries: ApiClientEntries;
  mainMenu: ApiClientMainMenu;
  tags: ApiClientTagList;
  tasks: ApiClientTasks;
  favorites: ApiClientFavorites;
  users: ApiClientUsers;
  user: ApiClientUser;
  survey: ApiClientSurvey;
  approvals: ApiClientApprovals;
  auth: ApiClientAuth;
  cancelData: ApiClientCancelData;
  session: ApiClientSession;
  timeTracking: ApiClientTimeTracking;
  group: ApiClientGroup;
  rateType: ApiClientRateType;
  integrations: ApiClientIntegrations;
  assistant: ApiClientAssistant;
  timeEntries: ApiClientTimeEntries;
  kiosk: ApiClientKiosk;
  analytics: ApiClientAnalytics;
  timer: ApiClientTimer;
  frontendConfig: ApiClientFrontendConfig;
  expense: ApiClientExpense;
  currency: ApiClientCurrency;
  storage: ApiClientStorage;
  guard: ApiClientGuard;
  modules: ApiClientModules;
  exporter: ApiClientExporter;
  customReport: ApiClientCustomReport;
  trelloPowerUp: ApiClientTrelloPowerUp;

  constructor(config: Pick<ConfigInterface, 'baseUrl'>) {
    super(config);
    this.config = config;
    this.reports = new ApiClientReport(config);
    this.helpdesk = new ApiClientHelpdesk(config);
    this.entries = new ApiClientEntries(config);
    this.mainMenu = new ApiClientMainMenu(config);
    this.tags = new ApiClientTagList(config);
    this.tasks = new ApiClientTasks(config);
    this.favorites = new ApiClientFavorites(config);
    this.users = new ApiClientUsers(config);
    this.user = new ApiClientUser(config);
    this.approvals = new ApiClientApprovals(config);
    this.survey = new ApiClientSurvey(config);
    this.auth = new ApiClientAuth(config);
    this.cancelData = new ApiClientCancelData(config);
    this.session = new ApiClientSession(config);
    this.timeTracking = new ApiClientTimeTracking(config);
    this.group = new ApiClientGroup(config);
    this.rateType = new ApiClientRateType(config);
    this.integrations = new ApiClientIntegrations(config);
    this.timeEntries = new ApiClientTimeEntries(config);
    this.kiosk = new ApiClientKiosk(config);
    this.analytics = new ApiClientAnalytics(config);
    this.timer = new ApiClientTimer(config);
    this.assistant = new ApiClientAssistant(config);
    this.frontendConfig = new ApiClientFrontendConfig(config);
    this.expense = new ApiClientExpense(config);
    this.currency = new ApiClientCurrency(config);
    this.storage = new ApiClientStorage(config);
    this.guard = new ApiClientGuard(config);
    this.modules = new ApiClientModules(config);
    this.exporter = new ApiClientExporter(config);
    this.customReport = new ApiClientCustomReport(config);
    this.trelloPowerUp = new ApiClientTrelloPowerUp(config);
  }
}
