import { IconName } from '@fortawesome/fontawesome-common-types';
import { format } from 'date-fns';
import camelCase from 'lodash/camelCase';
import { WeekStart } from '@/redux/models/SettingModel/types';
import { ICurrency } from '@/services/RestApiClientFactory';
import { normalizeForSearch } from '@/utils/StringExtensions';
import { API_V3_DATE_FORMAT_REQUEST, API_V3_DATETIME_FORMAT_REQUEST } from '@/utils/constants';
import { translate } from './translate';

export * from './renderClassses';

export const isDev = (): boolean => process.env.NODE_ENV === 'development';

export const isNullOrUndef = (variable): boolean => variable === undefined || variable === null;

export const isNullOrUndefOrEmptyString = (variable): boolean => variable === undefined || variable === null || variable === '';

export const isTrue = (value): boolean =>
  // eslint-disable-next-line
  // @ts-ignore
  window.isTrue(value);
export const base64ToUTF8 = (str: string) => window.atob(str);

export const isLink = (str: string) => str.substring(0, 4) === 'http';

export const startOfWeek = (date: Date, startFrom = 0): Date => {
  const result = new Date(date);
  const first = result.getDate() - result.getDay() + startFrom;
  result.setDate(first);

  return result;
};

export const endOfWeek = (date: Date, startFrom = 0): Date => {
  const result = new Date(date);
  const last = result.getDate() - result.getDay() + 6 + startFrom;

  result.setDate(last);

  return result;
};

export const calculatePercentOfTotalTime = (time: number, totalTime: number) => {
  if (totalTime === 0) return 0;
  return Math.round((time / totalTime) * 100);
};

export const thousandSeparatorPrice = (price: number) => {
  const formattedPrice = price.toFixed(2).toString().split('.');
  formattedPrice[0] = formattedPrice[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return formattedPrice.join('.');
};

export const returnPriceWithCurrencySymbol = (price: number, currency: Pick<ICurrency, 'symbol' | 'symbolPosition'>) => {
  const currencySymbol = currency ? currency.symbol : '$';
  const formattedPriceWithSeparator = thousandSeparatorPrice(price);

  if (currency) {
    return +currency.symbolPosition === 0 ? (currencySymbol + formattedPriceWithSeparator) : (`${formattedPriceWithSeparator} ${currencySymbol}`);
  }
};

export const weekStartNumberForDayPicker = (start: WeekStart) => {
  switch (start) {
    case WeekStart.SUNDAY:
      return 0;
    default:
      return 1;
  }
};

export const weekStartSettingToNumber = (weekStartSetting: string): 0 | 6 | 2 | 1 | 3 | 4 | 5 => {
  switch (weekStartSetting) {
    case '1':
      return 6;
    default:
      return 0;
  }
};

export const parseStringToDuration = (str: string, maxDefaultHours: number) => {
  if (!str) return 0;

  let seconds = 0;
  let hours;

  str = str.replace(/,/g, '.');

  if (str.match(/\d*:\d*\s*h/)) {
    hours = str.match(/(\d*):\d*\s*h/);
  } else {
    hours = str.match(/-?\d*(([.,])\d*)?\s*h/);
  }

  const minutes = str.match(/-?\d+(([.,])\d*)?\s*m/);
  const sec = str.match(/-?\d+(([.,])\d*)?\s*s/);
  const minutesAfterHour = str.match(/(h\s*\d+)$/); // 1h30
  const pureFloat = str.match(/^-?\d*(([.,])\d*)?$/);
  const hourMin = str.match(/(-?\d*(([.,])\d*)?\s*)?:(-?\d*(([.,])\d*)?\s*)?/);

  if (hours) seconds += parseFloat(hours[0]) * 3600;
  if (minutes) {
    seconds += parseFloat(minutes[0]) * 60;
  } else if (minutesAfterHour) {
    seconds += parseFloat(minutesAfterHour[0].substring(1)) * 60;
  }
  if (sec) seconds += parseFloat(sec[0]);

  if (pureFloat) {
    let def = parseFloat(pureFloat[0]);

    if (def > maxDefaultHours) {
      def *= 60;
    } else {
      def *= 3600;
    }

    seconds += def;
  }

  if (hourMin) {
    if (hourMin[1] === undefined && hourMin[4] !== undefined) seconds += parseFloat(hourMin[4]) * 60;
    else {
      if (hourMin[1] !== undefined && hourMin[4] === undefined) seconds += parseFloat(hourMin[1]) * 3600;
      else {
        if (hourMin[1] !== undefined && hourMin[4] !== undefined) {
          // for special case where seconds are added from hours and hourMin (doubled)
          // ex. 1:15h
          if (seconds > 0 && hours) {
            seconds -= parseFloat(hours[0]) * 3600;
          }

          seconds += parseFloat(hourMin[1]) * 3600 + parseFloat(hourMin[4]) * 60;
        } else if (hourMin[1] === undefined && hourMin[4] === undefined) seconds += 0;
      }
    }
  }

  return seconds;
};

/**
 * Format date to UTC - for JSON.stingify
 *  - use before JSON.stingify, then the date and time will still be the same with timezone 0(Z0)
 */
export const formatDateToUTC = (date: Date) => new Date(Date.UTC(
  date.getFullYear(),
  date.getMonth(),
  date.getDate(),
  date.getHours(),
  date.getMinutes(),
  date.getSeconds(),
));

export const useErrorMessageReports = (code: 500 | 403 | 404 | 408 | 429 | number) => {
  switch (code) {
    case 500:
      return translate('ErrorMessage.server_error');
    case 403:
      return translate('ErrorMessage.not_authorized_action');
    case 408:
      return translate('ErrorMessage.timeout_network');
    case 429:
      return translate('ErrorMessage.too_many_requests');
    case 404:
      return translate('ErrorMessage.not_found');
    default:
      return translate('ErrorMessage.default_error_message');
  }
};

export const convertEnumValuesToArray = (enumToConvert) => Object.keys(enumToConvert).map(key => enumToConvert[key]);

export const addLeadingZero = (value: number): string => `${value < 10 ? '0' : ''}${value}`;

export const sortObjectsByKey = <T extends object>(arr: Array<T>, itemKey: string, listKey: string) => {
  if (arr.length === 0) return arr;

  arr.sort((a, b) => {
    const nameA = a?.[itemKey] ? normalizeForSearch(a[itemKey]) : '';
    const nameB = b?.[itemKey] ? normalizeForSearch(b[itemKey]) : '';

    return nameA.localeCompare(nameB);
  });

  arr.forEach(item => Array.isArray(item[listKey]) && item[listKey].length && sortObjectsByKey(item[listKey], itemKey, listKey));

  return arr;
};

export const findParentByClass = (element: HTMLElement, className: string) => {
  if (element.classList.contains(className)) {
    return element;
  }

  const parent = element.parentNode as HTMLElement;

  if (!parent || !parent.classList) {
    return null;
  }

  if (parent.classList.contains(className)) {
    return parent;
  }

  return findParentByClass(parent, className);
};

export const getAllElementAttributes = (element: HTMLElement, filter?: (name: string) => unknown) => element.getAttributeNames().reduce((acc, name) => ({
  ...acc,
  [name]: filter ? filter(element.getAttribute(name)) : element.getAttribute(name),
}), {});

export const convertKeysToCamelCase = (obj: { [key: string]: string }) => {
  const kebabObj = {};

  for (const key in obj) {
    const kebabKey = camelCase(key);
    kebabObj[kebabKey] = obj[key];
  }

  return kebabObj;
};

export const convertDeprecatedFontawesomeIconToNew = (iconName?: string) => {
  if (!iconName) {
    return null;
  }

  return iconName.replace('dfa-', '') as IconName;
};

export const getResourceFileUrlByTemplate = (filePath: string, extension: 'css' | 'js') => {
  const { staticContentTemplateUrl } = REACT_CONFIG;

  return staticContentTemplateUrl
    .replace('{pathToFile}', filePath)
    .replace('{fileExt}', extension);
};

export const convertUnicode = (text: string) => text.replace(/\\+u([0-9a-fA-F]{4})/g, (a, b) =>
  String.fromCharCode(parseInt(b, 16)));

export const convertDateToV3ApiFormat = (date: Date, dateOnly = false) => format(date, dateOnly ? API_V3_DATE_FORMAT_REQUEST : API_V3_DATETIME_FORMAT_REQUEST);

export const limitDecimalPlaces = (input: HTMLInputElement, maxDecimalPlaces: number) => {
  if (input.value !== '') {
    const value = parseFloat(input.value);
    const roundedValue = parseFloat(value.toFixed(maxDecimalPlaces));

    if (value !== roundedValue) {
      input.value = roundedValue.toFixed(maxDecimalPlaces);
    }
  }
};
export const getCSRFCookieValue = (cookieName: string): string | null => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${cookieName}=`)) {
      return cookie.substring(cookieName.length + 1);
    }
  }

  return null;
};

/**
 * Get mobile app
 */
export const getMobileApp = (type: 'iPhone' | 'Android') => {
  window.location.replace(type === 'Android' ? 'https://play.google.com/store/apps/details?id=com.timecamp.mobile' : 'https://itunes.apple.com/us/app/my.app/id881820002?ls=1&mt=8');
};

export const convertHTMLAttributesToReactProps = (htmlContainer: HTMLElement) => convertKeysToCamelCase(getAllElementAttributes(htmlContainer, name => {
  try {
    return JSON.parse(name);
  } catch (e) {
    return name;
  }
}));
