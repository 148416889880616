import { fork, put, takeLatest } from 'redux-saga/effects';
import { restApiClient } from '@/services';
import { SettingModelActions } from './types';
import { updateIntegrationsLoading } from './actions';

export const SettingModelSagas = [
  function* () {
    yield fork(function* () {
      yield takeLatest(SettingModelActions.SYNC_INTEGRATIONS, syncIntegrationsFunc);
    });
  },
];

export function* syncIntegrationsFunc() {
  const controller = new AbortController();
  const { signal } = controller;

  yield put(updateIntegrationsLoading(true));
  yield restApiClient.integrations.synchIntegrations(signal);

  yield put(updateIntegrationsLoading(false));
}
