import { useMemo } from 'react';
import { useThemeContext } from '@/contexts/ThemeContext';
import { Theme } from '@/contexts/constants';
import { ImageFormat, ImageType } from '@/types';
import { config } from '@/config';

export const useGetStaticImageUrl = () => {
  const getThemedImageUrl = (type: ImageType, name: string, format: ImageFormat) => {
    const { theme } = useThemeContext();

    const isDarkMode = useMemo(() => theme === Theme.DARK, [theme]);

    return useMemo(() => `${config.staticContentBaseUrl}css/images/${type}/${name}${isDarkMode ? '_dark' : '_light'}.${format}`, [type, name, format]);
  };

  const getImageUrl = (type: ImageType, name: string, format: ImageFormat) => useMemo(() => `${config.staticContentBaseUrl}css/images/${type}/${name}.${format}`, [type, name, format]);

  return { getThemedImageUrl, getImageUrl };
};

