
import { INewTaskResponse } from '@/redux/models/TaskModel';
import { TaskPickerResponseApi } from '@/components/common/pickers/TaskPicker/types';
import { ApiClientBase } from '@/services/RestApiClientFactory/modules/ApiClientBase';
import {
  FetchTaskPickerParams,
  FetchTaskPickerResponse,
  SearchTaskPickerParams,
  SearchTaskPickerResponse,
  SpecificTasksResult,
  TaskPickerTasksBySelectorParams,
  TaskPickerTasksBySelectorResponse,
} from '@/services/RestApiClientFactory/modules/ApiClientTasks/ApiClientTasks.types';

export class ApiClientTasks extends ApiClientBase {

  async createNewTask(data) {
    const { body, signal = null } = data;

    if (!body) return null;

    let result: INewTaskResponse = null;

    try {
      const { data } = await this.fetchData<INewTaskResponse>({
        queryString: 'third_party/api/tasks',
        method: 'POST',
        apiVersion: 0,
        body,
        signal,
      });

      result = data;
    } catch (error) {
      throw new Error(error.message);
    }

    return result;
  }

  async fetchSpecificTasks(
    signal: AbortSignal,
    taskIds,
    returnAsArray = 1,
  ) {
    if (taskIds.reduce((acc, taskId) => +taskId + acc, 0) === 0) {
      return {};
    }

    const { data, ok } = await this.fetchData<SpecificTasksResult>({
      queryString: `third_party/api/tasks?format=json&return_as_array=${returnAsArray}&task_id=${taskIds.join(',')}`,
      signal,
      apiVersion: 0,
    });

    if (!ok) {
      throw new Error('Task not found');
    }

    return data;
  }

  async fetchTasks(
    signal: AbortSignal,
    context,
    syncToken,
    archived,
    allowGlobalAdminAccess,
    permissions,
  ) {
    let result: TaskPickerResponseApi['tasks'] = [];
    let newSyncToken = '0';

    try {
      const { data, status, headers } = await this.fetchData<TaskPickerResponseApi['tasks']>({
        queryString: `time_tracking/ajax_get_task_picker_tasks/${
          [
            syncToken ?? 0,
            archived,
            allowGlobalAdminAccess,
            permissions,
            context,
          ].join('/')}`,
        signal,
        apiVersion: 0,
      });

      if (status === 200) {
        result = data;
      }
      newSyncToken = headers.get('X-TASKS-SYNC-TOKEN') ?? '';
    } catch (error) {
      throw new Error(error.message);
    }

    return { tasks: result, syncToken: newSyncToken };
  }

  async fetchTaskPicker({
    params,
    signal,
  }: {
    params?: FetchTaskPickerParams;
    signal?: AbortSignal
  }) {
    params = {
      status: 'all',
      page: 1,
      parentId: 0,
      limit: 100,
      ...params,
    };
    const { data } = await this.fetchData<FetchTaskPickerResponse>({
      queryString: 'taskPicker/list',
      body: params,
      method: 'POST',
      signal,
    });

    return data;
  }

  async taskPickerSearch({
    params,
    signal,
  }: {
    params?: SearchTaskPickerParams;
    signal?: AbortSignal
  }) {
    params = {
      status: 'all',
      ...params,
    };
    const { data } = await this.fetchData<SearchTaskPickerResponse>({
      queryString: 'taskPicker/search',
      body: params,
      method: 'POST',
      signal,
    });

    return data;
  }

  async taskPickerTasksBySelector(
    {
      params,
      signal,
    }: {
      params?: TaskPickerTasksBySelectorParams;
      signal?: AbortSignal
    },
  ) {

    const { data } = await this.fetchData<TaskPickerTasksBySelectorResponse>({
      queryString: 'taskPicker/select',
      body: {
        ...params,
      },
      method: 'POST',
      signal,
    });

    return data;
  }
}
