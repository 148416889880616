import { Map } from 'immutable';
import { AnchorHTMLAttributes, ButtonHTMLAttributes, ReactNode } from 'react';
import { Analytics } from '@/services/Analitics';

export type Id = string;

export enum DurationFormat {
  FORMAT_CLASSIC = 'FORMAT_CLASSIC',
  FORMAT_CLASSIC_WITH_SECONDS = 'FORMAT_CLASSIC_WITH_SECONDS',
  FORMAT_HH_MM = 'FORMAT_HH_MM',
  FORMAT_HH_MM_SS = 'FORMAT_HH_MM_SS',
  FORMAT_DECIMAL_WITH_COMMA = 'FORMAT_DECIMAL_WITH_COMMA',
  FORMAT_DECIMAL_WITH_DOT = 'FORMAT_DECIMAL_WITH_DOT',
}

export enum TimeUnits {
  DAYS = 'days',
  HOURS = 'hours',
  MINUTES = 'minutes',
  SECONDS = 'seconds',
  MILLISECONDS = 'milliseconds',
}

export enum TypeOfSetting {
  USER_SETTING = 'user',
  GROUP_SETTING = 'group'
}

export type CustomEventDetailValue = {
  changedValue: string;
  type: string;
  settingName: string;
};

export type MaybeUndefined<T> = T | undefined;
export type Maybe<T> = T | null;
export type Dict<T> = { [_: string]: T };
export type AnyDict = Dict<any>;

export type AnyMap = Map<string, any>;

export enum ArrowName {
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
}

export enum AccountPlan {
  FREE = 'free',
  STARTER = 'starter',
  PREMIUM = 'premium',
  BASIC = 'basic',
  ULTIMATE = 'ultimate',
}

export enum FileType {
  PDF = 'pdf',
  EXCEL = 'excel',
  CSV = 'csv',
  GOOGLE_SPREADSHEETS = 'google spreadsheet',
}

export enum MimeTypes {
  PDF = 'application/pdf',
  CSV = 'text/csv',
  EXCEL = 'application/vnd.ms-excel',
}

export enum FileExtensions {
  PDF = '.pdf',
  CSV = '.csv',
  EXCEL = '.xlsx',
}

export enum FeatureFlags {
  REPORT_EXPORT_TO_PDF = 'REPORT_EXPORT_TO_PDF',
  REPORT_EXPORT_TO_OTHER_FORMAT = 'REPORT_EXPORT_TO_OTHER_FORMAT',
  SPECIFIC_BILLING_RATES = 'SPECIFIC_BILLING_RATES',
  ACCESS_TO_GOOGLE_API_FEATURE = 'ACCESS_TO_GOOGLE_API_FEATURE',
  CAN_CREATE_PROJECTS_MANUALLY = 'CAN_CREATE_PROJECTS_MANUALLY',
  CAN_HAVE_USER_GROUPS = 'CAN_HAVE_USER_GROUPS',
  ACCESS_TO_TASK_PICKER_2024 = 'ACCESS_TO_TASK_PICKER_2024',
  ACCESS_TO_DETAILED_REPORT_V_2022 = 'ACCESS_TO_DETAILED_REPORT_V_2022',
  ACCESS_TO_SUMMARY_REPORT_V_2022 = 'ACCESS_TO_SUMMARY_REPORT_V_2022',
  ACCESS_TO_TIMER_CONTROLLER_REACT = 'ACCESS_TO_TIMER_CONTROLLER_REACT',
  ACCESS_TO_CUSTOM_REPORT = 'ACCESS_TO_CUSTOM_REPORT',
  CUSTOM_REPORT_AD_ENABLED = 'CUSTOM_REPORT_AD_ENABLED',
}

export const FileTypeDetails = {
  [FileType.PDF]: {
    mime: MimeTypes.PDF,
    extension: FileExtensions.PDF,
  },
  [FileType.CSV]: {
    mime: MimeTypes.CSV,
    extension: FileExtensions.CSV,
  },
  [FileType.EXCEL]: {
    mime: MimeTypes.EXCEL,
    extension: FileExtensions.EXCEL,
  },
};

export enum ReportEntityType {
  USER = 'User',
  TAG = 'Tag',
  TASK = 'Task',
  ENTRY = 'Entry',
}

export enum LANGUAGE {
  EN = 'en_EN',
  EN_DUBAI = 'en_DUBAI',
  PL = 'pl_PL',
  DE = 'de_DE',
  PT = 'pt_BR',
  ES = 'es_ES',
  FR = 'fr_FR',
}

export const MeridianValue = {
  AM: 'am',
  PM: 'pm',
};

export const TAG_LIST_EMPTY_VALUE = 'nolist';

export enum DetailedSortColumnTypes {
  TIMESTAMP = 'timestamp',
  TASK_NAME = 'taskName',
  BILLABLE = 'billable',
  USER_NAME = 'userName',
  DURATION = 'duration',
  NOTE = 'note',
  ENTRY_ID = 'entryId',
  TAGS = 'tags',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  DATE = 'date'
}

export type Entry = {
  id: number,
  startTime: string | undefined,
  endTime: string | undefined,
  duration: number | undefined,
  date: string | undefined,
  note: string | undefined | null,
  description: string | undefined,
  invoiceId: number | undefined,
  taskId: number | undefined,
  billable: boolean | undefined,
  analytics: Analytics | undefined,
};

export type BackendEntry = {
  id: number,
  start_time: string | undefined,
  end_time: string | undefined,
  duration: number | undefined,
  date: string | undefined,
  note: string | undefined,
  description: string | undefined,
  invoiceId: number | undefined,
  task_id: number | undefined,
  billable: boolean | undefined,
};

export type TicketEntry = {
  requester: {
    email: string;
  };
  ticket: {
    subject: string;
    message: string;
  };
  tags?: Array<{
    id: string;
  }>;
};

export type TicketFiles = {
  ticketUuid: string;
  files: Array<File>
};

export type ConfirmationModalInterface = {
  message: string | JSX.Element,
  header?: JSX.Element | string,
  label?: {
    confirm?: string;
    cancel?: string;
  },
  onClick: () => void,
  onClose: () => void,
  isOpen: boolean,
  hrTag?: boolean
};

export interface NestedListItem {
  id: number | string,
  parentId: string | null;
  level: number;
  isExpandable: boolean;
  isSelectable: boolean;
  isAlwaysExpanded?: boolean;
}

export type IPlans = 'starter' | 'premium' | 'basic' | 'ultimate';

export type IChildren = {
  children: ReactNode;
};

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;
export type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

export enum ImageFormat {
  PNG = 'png',
  JPG = 'jpg',
  SVG = 'svg',
}

export enum ImageType {
  ERROR = 'errors',
  REPORTS = 'reports',
  CUSTOM_ICONS = 'customIcons',
}
