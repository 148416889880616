import { timeService } from '@/services/index';
import { DurationFormat, TimeUnits } from '@/types';
import { DurationFormatterInterface } from './DurationFormatterInterface';

export default class DurationFormatter implements DurationFormatterInterface {
  default(milliseconds: number, durationFormat: DurationFormat): string {
    return timeService.convertTimestampToHumanDuration(
      milliseconds * 1000,
      TimeUnits.HOURS,
      TimeUnits.MINUTES,
      durationFormat,
    );
  }
}
