import { useContext } from 'react';
import { NotificationsContext } from '@/contexts/NotificationsContext';

export const useNotifications = () => {
  const notificationsContext = useContext(NotificationsContext);

  const {
    alerts = [],
    dispatchNotification,
    dispatchNotificationRemove,
  } = notificationsContext || {};

  return {
    alerts,
    dispatchNotification,
    dispatchNotificationRemove,
  };
};
