import { action } from 'typesafe-actions';
import { Theme } from '@/contexts/constants';
import { RuntimeSetting, SettingModelActions, SettingsModelStateFields } from './types';

export const fetchSettingSuccess = (data) => action(SettingModelActions.FETCH_SETTING_SUCCESS, data);

export const setPageTitle = (title) => action(SettingModelActions.FETCH_SETTING_SUCCESS, {
  type: SettingsModelStateFields.RUNTIME_SETTINGS,
  name: RuntimeSetting.TITLE,
  value: title,
});

export const syncIntegrations = () => action(SettingModelActions.SYNC_INTEGRATIONS);

export const updateIntegrationsLoading = (data: boolean) => action(SettingModelActions.UPDATE_INTEGRATIONS_LOADING, data);

export const setThemeSetting = (theme: Theme) => action(SettingModelActions.SET_THEME, theme);
