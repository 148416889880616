import i18n from '@/services/i18n';

export function translate(text: string, props?: Record<string, string | number>): string {
  return i18n.t(text, props);
}

export function translateArray(textArray: Array<string>): Array<string> {
  return i18n.t(textArray, { returnObjects: true });
}

